/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '~node_modules/angular-notifier-3/styles.css';
/* Increase the width of the scrollbar track */
.simplebar-scrollbar::before {
    width: 7px;
    /* Adjust the width as needed */
}

.simplebar-scrollbar::before {
    background-color: #cbd2d9 !important;
}

input {
  background-color: white!important;
}
