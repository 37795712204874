// Emoji Picker
.emoji-mart {
  position: absolute;
  bottom: 35px;
  right: 0;
}

.emoji-mart-preview {
  display: none;
}

.copyclipboard-alert {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}


.dropdown-toggle::after {
  display: none !important;
}

.chat-welcome-section {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--bs-card-bg);

  @media (max-width: 991.98px) {
    display: none;
  }
}

// Reply Msg
.send-msg {
  @include media-breakpoint-up(lg) {
    position: relative;
    z-index: 1;
  }
}

.replyCard {
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #e9ebec;
  border-top: 1px solid var(--vz-border-color);
  overflow: hidden;
  opacity: 0;
  bottom: 0;
  border-radius: 0;
  transition: all 0.4s;
}

.replyCard.show {
  -webkit-transform: translateY(-88px);
  transform: translateY(-88px);
  opacity: 1;
}

.replymessage-block {
  padding: 12px 20px;
  margin-bottom: 8px;
  text-align: left;
  border-radius: 4px;
  background-color: rgba(114, 105, 239, 0.2);
  border-left: 2px solid rgba(114, 105, 239, 1);
}

// Dark - Light Mode
body[data-bs-theme="dark"] {
  .layout-mode-dark {
    display: none;
  }

  .layout-mode-light {
    display: inline-block;
  }
}

.layout-mode-light {
  display: none;
}

.offcanvas.show {
  visibility: visible;
}
