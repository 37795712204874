//
// root.scss
//


//theme-light
:root,
[data-bs-theme="light"] {
    --#{$prefix}white-text: #fff;
    --#{$prefix}secondary: #{$gray-200};
    --#{$prefix}body-secondary-color: #{$gray-600};
    --#{$prefix}text-muted: #{$gray-600};

    // card
    --#{$prefix}card-logo-dark: block;
    --#{$prefix}card-logo-light: none;

    // sidebar
    --#{$prefix}sidebar-bg: #{$white};
    --#{$prefix}sidebar-menu-item-color: #878a92;
    --#{$prefix}sidebar-menu-item-active-color: var(--#{$prefix}primary);
    --#{$prefix}sidebar-menu-item-active-bg: #f7f7ff;
    --#{$prefix}sidebar-sub-bg: #f5f7fb;

    //   // Theme mode icon
    --#{$prefix}theme-mode-icon: "\eefb";

    // accordion
    --#{$prefix}accordion-button-bg: #{$gray-200};
    --#{$prefix}accordion-bg: #{$white};

    // Dropdown
    --#{$prefix}dropdown-bg: #{$white};
}
//Theme Dark
@if $enable-dark-mode {
    @include color-mode(dark, true) {
        --#{$prefix}white: #{$white-dark};
        --#{$prefix}white-rgb: #{to-rgb($white-dark)};
        --#{$prefix}light: #{$light-dark};
        --#{$prefix}light-rgb: #{to-rgb($light-dark)};
        --#{$prefix}dark: #{$dark-dark};
        --#{$prefix}dark-rgb: #{to-rgb($dark-dark)};
        --#{$prefix}secondary: #{$secondary-dark};
        --#{$prefix}secondary-rgb: #{to-rgb($secondary-dark)};
        --#{$prefix}text-muted: #{$text-muted-dark};
        --#{$prefix}text-muted-rgb: #{to-rgb($text-muted-dark)};

        // // sidebar
        --#{$prefix}sidebar-bg: #{$sidebar-bg-dark};
        --#{$prefix}sidebar-menu-item-color: #{$sidebar-menu-item-color-dark};
        --#{$prefix}sidebar-menu-item-active-color: #{$sidebar-menu-item-active-color-dark};
        --#{$prefix}sidebar-menu-item-active-bg: #{$sidebar-menu-item-active-bg-dark};
        --#{$prefix}sidebar-sub-bg: #{$sidebar-sub-bg-dark};

        // accordion
        --#{$prefix}accordion-button-bg: #36404a;
        --#{$prefix}accordion-bg: transparent;

        //   // Theme mode icon
        --#{$prefix}theme-mode-icon: "\f105";

        // Dropdown
        --#{$prefix}dropdown-bg: #{$dropdown-bg-dark};
    }
}
