// 
// authentication.scss
//

.auth-logo{
    .logo{
        margin: 0px auto;
    }

    .logo-dark{
        display: $display-block;
    }

    .logo-light{
        display: $display-none;
    }
}

[data-bs-theme="dark"]{
    .auth-logo{
        .logo-dark{
            display: $display-none;
        }
        .logo-light{
            display: $display-block;
        }
    }
}
